:picture-in-picture {
  opacity: 0;
}

/* Removed Visual Search For Microsoft Edge */
.no-visual-search {
  pointer-events: none;
}

/* Removed Search For Microsoft Edge */

@keyframes hrms-anim-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  --bs-gutter-x: 1rem;

  // @include media-breakpoint-up(xxl) {
  //   padding-left: 24px;
  //   padding-right: 24px;
  // }

  @include media-breakpoint-xl {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include media-breakpoint-md {
    max-width: 100%;
  }
}

/* Common Styles for the ngx-toast starts here  */
.toast-container {
  .ngx-toastr {
    box-shadow: none;
    background-size: 20px 20px !important;

    .toast-title {
      font-weight: 600;
      font-size: 18px;
    }

    .toast-message {
      font-weight: 500;
      font-weight: 14px;
    }
  }
}
/* Common Styles for the ngx-toast ends here */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-inter;
}

/* Common Buttons styles code starts here */
%btn-cmn-nostyle {
  border: none;
  outline: none;
  text-decoration: none;
}

%btn-cmn-style1 {
  font-family: $font-inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 5px;
  padding: 10px 15px;
}

button,
a {
  &.btn {
    @extend %btn-cmn-style1;
  }

  &.btn-primary {
    @extend %btn-cmn-nostyle;
  }

  &.btn-secondary {
    @extend %btn-cmn-nostyle;
  }

  &.btn-tertiary {
    @extend %btn-cmn-nostyle;
  }

  &.btn-nostyle {
    @extend %btn-cmn-nostyle;
  }
  &.btn-sm {
    padding: 12px 24px;
  }

  &.btn-lg {
    padding: 24px 48px;
  }

  &.btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &.btn-icon-right {
    flex-direction: row-reverse;
  }
}
/* Common Buttons styles code ends here */

/* Common Tooltip styles starts here */

.hrms-cmn-tooltip-type1 .tooltip-inner {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

/* Common dropdown menu styles starts here */
.hrms-cmn-dropdown {
  > .dropdown-menu {
    --bs-dropdown-min-width: 130px;
    border-radius: 6px;
    border: none;
    .dropdown-item {
      font-size: 14px;
      padding: 6px 8px;
      font-weight: 500;
      .icon-img {
        margin-right: 6px;
      }
    }
  }
}

/* Common dropdown menu styles ends here */

/* Common Form control code starts here */

%hrms-formcontrol-typ1 {
  @extend %dft-transition;
  @include mixin-placeholder {
    opacity: 1;
  }
  @include mixin-borderstyle(all, 1px, solid);
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  border-radius: 8px;
  padding: 10px 15px;
  letter-spacing: 0;
}

%hrms-formcontrol-password {
  padding: 13px 30px 13px 15px;
}

%hrms-formcontrol-textarea {
  height: 70px;
  min-height: 70px;
}

%hrms-checkbox-ui-typ1 {
  display: flex;
  min-height: 24px;
  margin-bottom: 0;
  gap: 12px;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .form-check-input {
    height: 20px;
    width: 20px;
    @include mixin-borderstyle(all, 1px, solid);
    border-radius: 3px;
    margin-top: 0;
    cursor: pointer;
    &:focus {
      box-shadow: none;
    }
  }
  .form-check-label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
  // text-transform: capitalize;
}

%hrms-checkbox-ui-typ-lock {
  .form-check-input {
    float: none;
    clear: both;
    margin-top: 0;
    height: 34px;
    width: 34px;
    border: none;
    background-size: 30px;
    background-color: transparent;
    cursor: pointer;
    --bs-form-check-bg-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:anim='http://www.w3.org/2000/anim' anim='' anim:transform-origin='50%25 50%25' anim:duration='1' anim:ease='ease-in-out'%3e%3cg id='icon / heroicons / Outline / lock-open'%3e%3cpath id='Icon' d='M8 11V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21Z' stroke='%23667085' stroke-width='2' stroke-linecap='round'%3e%3c/path%3e%3c/g%3e%3c/svg%3e");
    &:focus {
      box-shadow: none;
    }
    &:checked {
      --bs-form-check-bg-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:anim='http://www.w3.org/2000/anim' anim='' anim:transform-origin='50%25 50%25' anim:duration='1' anim:ease='ease-in-out'%3e%3cg id='icon / heroicons / Outline / lock-closed'%3e%3cpath id='Icon' d='M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z' stroke='%23FDA29B' stroke-width='2' stroke-linecap='round'%3e%3c/path%3e%3c/g%3e%3c/svg%3e");
    }
  }
}
%hrms-radio-ui-typ1 {
  display: flex;
  min-height: 24px;
  margin-bottom: 0;
  gap: 12px;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .form-check-input {
    height: 24px;
    width: 24px;
    border: none;
    margin-top: 0;
    cursor: pointer;
    &:focus {
      box-shadow: none;
    }
  }
  .form-check-label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    // text-transform: capitalize;
  }
}

%hrms-cmn-btnform-typ1 {
  padding: 7px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  min-width: 130px;
}

%hrms-cmn-table-ui-style-typ1 {
  --bs-table-bg: $white;
  > thead {
    th {
      font-variation-settings:
        "wght" 700,
        "wdth" 75;
    }
    .hrms-tbl-infohead {
      margin-left: 2px;
      display: inline-flex;
      height: 16px;
      width: 16px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      border-radius: 50%;
      color: #262b2c;
      cursor: pointer;
    }
  }
}

.hrms-cmn-loader-wrap {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-hrms-loader;

  .hrms-cmn-loader-inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;

    .hrms-cmn-spinner {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      @include mixin-borderstyle(all, 7px, solid);
      -webkit-animation: 1s hrms-anim-spin linear infinite;
      animation: 1s hrms-anim-spin linear infinite;
    }
  }
}

.hrms-cmn-control-loader-wrap {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  @include mixin-borderstyle(all, 2px, solid);
  -webkit-animation: 1s ease-spin linear infinite;
  animation: 1s ease-spin linear infinite;
}

.hrms-cmn-incontrol-loader-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hrms-formbox {
  .hrms-formcontrol-div {
    > .form-label {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 10px;
      &.form-label-with-icon {
        display: flex;
        align-items: center;
        gap: 6px;
        i {
          font-size: 15px;
        }
      }
    }
    + .hrms-formcontrol-div {
      margin-bottom: 25px;
    }
  }

  .hrms-fcontrol-wicon-left {
    position: relative;
    > i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      font-size: 12px;
    }
    .form-control {
      padding: 10px 15px 10px 35px;
    }
  }

  .form-control {
    @extend %hrms-formcontrol-typ1;
    &.is-invalid {
      background-image: none;
    }
    &.is-invalid {
      background: none;
      box-shadow: none;

      + .invalid-feedback {
        margin-top: 5px;
        line-height: 14px;
        font-size: 12px;
        font-weight: 400;

        @include media-breakpoint-down(sm) {
          font-size: 11px;
        }
      }
    }

    &:disabled {
      pointer-events: none;
      cursor: no-drop;
    }
  }

  .input-group {
    .hrms-cmn-ipg-btn {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .is-input-group-invalid {
    + {
      .invalid-feedback {
        margin-top: 5px;
        line-height: 14px;
        font-size: 12px;
        font-weight: 400;

        @include media-breakpoint-down(sm) {
          font-size: 11px;
        }
      }
    }
  }

  .hrms-chkbox-typ1 {
    @extend %hrms-checkbox-ui-typ1;
  }

  .hrms-radio-typ1 {
    @extend %hrms-radio-ui-typ1;
  }

  .hrms-form-btn {
    font-size: 16px;
    padding: 7px 10px;
    border-radius: 4px;
    max-width: 200px;
    width: 100%;
  }

  .hrms-cmn-frmbtn-box {
    margin-block: 10px;
    display: flex;
    justify-content: center;
    gap: 24px;
  }
}

/* Common Form control code ends here */

.hrmsrag-app {
  height: 100%;
}
.main-content-section {
  height: 100%;

  .main-containerfluid {
    height: 100%;

    > .row {
      height: 100%;

      > .col-12 {
        .main-section-inner {
          height: 100%;

          .main-pageroute-section {
            // height: calc(100% - 81px);
            height: 100%;
            @extend %dft-transition;

            .main-pageroute-innerbox {
              height: 100%;
              .hrms-cmn-ipage {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

.hrms-cmn-innerpage-wrap {
  padding-block: 30px;
}

.hrms-chat-cmn-bubble {
  padding: 20px 24px;
  border-radius: 8px;
  .hrms-chat-cmn-bubble-inner {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    // * {
    //   font-size: inherit;
    //   font-weight: inherit;
    //   line-height: inherit;
    //   font-weight: 600;
    // }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
      font-size: medium;
    }
    .hrms-chat-markdownbind {
      strong {
        font-weight: 600;
      }
    }
    .file-container {
      display: flex;
      align-items: center;
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      background-color: #f9f9f9;
      height: 65px;
      overflow: hidden;
      margin-bottom: 7px;
    
      .file-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .file-details {
        flex: 1;
        padding-left: 10px;
        .file-title {
          font-size: 12px;
          font-weight: 500;
          color: #333;
          line-height: 1.2;
          display: -webkit-box;        
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;     
          overflow: hidden;           
          text-overflow: ellipsis;
        }
    
        .file-meta {
          font-size: 12px;
          color: #999;
        }
      }

      .file-download {
        display: none;
        margin-right: 20px;
      }
    }  
    .file-container:hover {
      cursor: pointer;
      .file-download {
        display: block;
      }
    }  
  }
}
.hrms-chat-cmn-profile {
  @include mixin-profile-circle(32px, 32px);
}
.hrms-chat-cmn-name {
  color: #1e1f22;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
}
.hrms-chat-cmn-duration {
  color: #1e1f22;
  font-size: 10px;
  font-weight: 400;
  line-height: 8px;
  letter-spacing: 0;
}

.hrms-chat-markdownbind {
  table,
  th,
  td {
    padding: 5px;
    border: 1px solid #b9b9b9;
  }

  thead {
    background-color: rgba(#02b56d, 0.12) !important;
  }
  tr:first-child {
    border-top-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 6px;
  }
  td:first-child {
    border-bottom-left-radius: 6px;
  }
  td:last-child {
    border-bottom-right-radius: 6px;
  }
  table {
    width: 80%;
    background-color: #ffffff;
    margin-top: 20px;
  }

  img[title="pdf-icon"] {
    margin-right: 20px;
  }
  img[title="download-icon"] {
    margin-left: 30px;
  }
}

app-not-found {
  .hrms-notfound-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .notfound-inner {
      text-align: center;

      h1 {
        font-size: 100px;
        font-weight: 800;
      }

      .not-found-content {
        h4 {
          font-size: 28px;
          font-weight: 700;
          line-height: 38px;
          margin-bottom: 15px;
        }

        p {
          margin-bottom: 15px;
        }
      }
    }
  }
}

app-header {
  .hrms-header-wrap {
    width: 100%;
    max-width: 338px;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 350px;
    z-index: 1;
    .header-ibox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 5px;
      .header-left-box {
        @extend %dft-transition;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        // @include mixin-borderstyle(right, 1px, solid);
        border-right-color: #e9e9e9;
        .hlogo-box {
          max-width: 165px;
          width: 100%;
          .m-logo {
            display: flex;
            width: 40px;
            height: 40px;
          }
        }
        .hsidebar-pbox {
          display: flex;
          align-items: center;
          gap: 10px;
          button {
            display: flex;
            gap: 10px;
            width: 40px;
            height: 38px;
            padding: 6px;
            font-size: 25px;
            justify-content: center;
            align-items: center;
            i {
              font-size: 16px;
            }
          }
        }
      }
      .header-mid-box {
        width: 100%;
        max-width: 400px;
        @include mixin-borderstyle(all, 0.6, solid);
        border-color: #e2e2e2;
        border-radius: 8px;
        background-color: #eeeeee;
        .hrms-nav-mid-menulinks {
          gap: 2px;
          > li {
            > a {
              color: #3b3b3b;
              font-size: 10px;
              font-weight: 600;
              line-height: 12px;
              letter-spacing: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              &.hrms-chat-mitem {
                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: center;
                .hrms-chat-cnt {
                  height: 20px;
                  width: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: inherit;
                  border-radius: 4px;
                  background-color: rgba(16, 163, 127, 0.15);
                }
              }

              &:hover,
              &:focus,
              &.active {
                background-color: $white;
                color: #14b48d;
              }
            }
          }
        }
      }
      .header-right-box {
        .hrms-auth-userprofile-btn {
          @include mixin-profile-circle(34px, 34px);
          border-radius: 8px;
          padding: 0;
          &::after {
            display: none;
          }
        }
      }
    }
    &.is-notexpanded {
      .header-left-box {
        flex: 0;
        .hsidebar-pbox {
          .toggle-btn {
            background-color: $white;
          }
        }
      }
    }
  }
}

app-chat-sidebar {
  .hrms-chat-sidebar-wrap {
    height: 100%;
    padding-top: 55px;
    @include mixin-borderstyle(right, 1px, solid);
    border-right-color: #e9e9e9;
    .hrms-chat-sidebar-top {
      padding-inline: 15px;
      .hrms-chat-sidebar-top-inner {
        display: flex;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .hrms-chatp-titlebox {
          .hrms-sidebar-title {
            color: $secondary;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            @include mixin-text-truncate();
          }
        }
        .hrms-chatp-qoperbox {
          .hrms-chat-qoprlist {
            display: flex;
            align-items: center;
            gap: 15px;
            margin: 0;
          }
        }
      }
    }

    .hrms-chat-sidebar-btm {
      padding-inline: 15px;
      height: calc(100% - 100px);
      overflow: auto;
      margin-top: 10px;
      scroll-snap-type: y mandatory;
      .hrms-chathistroy-list {
        display: flex;
        flex-direction: column;
        gap: 5px;
        > li {
          scroll-snap-align: start none;
          > .btn-hrms-chathist-btn {
            @extend %btn-cmn-nostyle;
            width: 100%;
            display: flex;
            gap: 7px;
            align-items: flex-start;
            padding: 16px 10px;
            border-radius: 8px;
            .hrms-chat-bhist-icon {
              @include mixin-profile-circle(14px, 14px);
              border-radius: 4px;
              flex: 1 0 auto;
              img {
                height: inherit;
                width: inherit;
                object-position: top center;
              }
            }
            .hrms-chat-bhist-info {
              text-align: left;
              width: calc(100% - 26px);
              display: flex;
              flex-direction: column;
              gap: 7px;
              .hrms-chat-bhist-title-wtime-box {
                position: relative;
                display: flex;
                align-items: center;
                gap: 7px;
                .hrms-chat-bhist-title {
                  color: $secondary;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 17px;
                  letter-spacing: 0;
                  @include mixin-text-truncate();
                }
                .hrms-chat-timed {
                  color: rgba(#5f5f5f, 0.6);
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 8px;
                  letter-spacing: 0;
                  text-align: right;
                  flex: 1;
                  white-space: nowrap;
                }
              }
              .hrms-chat-bhist-desc {
                color: #6d717c;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0;
                @include mixin-truncate(2);
              }
            }
            &.active,
            &:hover,
            &:focus {
              background-color: #eff8ef;
            }
          }
        }
      }
    }
  }
}

app-chat-request {
  .hrms-chat-request-wrap {
    position: relative;

    .hrms-chat-requ-profile {
      position: absolute;
      top: 3px;
      left: -16px;
    }
    .hrms-chat-requ-topbox {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      margin-left: 20px;
      margin-bottom: 2px;
    }
    .hrms-chat-requ-body-box {
      background-color: $white;
      color: $chatcolor;
      display: flex;
      gap: 10px;
      align-items: flex-start;
      .hrms-chat-cmn-bubble-edit {
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
      }
    }
  }
}

app-chat-response {
  .hrms-chat-response-wrap {
    position: relative;

    .hrms-chat-resp-profile {
      position: absolute;
      top: 3px;
      right: -16px;
    }
    .hrms-chat-resp-topbox {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 2px;
      .hrms-chat-resp-pagenav {
        .pagination {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          > li {
            &.page-item {
              color: #1e1f22;
              font-size: 10px;
              font-weight: 400;
              line-height: 8px;
              letter-spacing: 0;
              &.hrms-pg-d-length {
                min-width: 20px;
              }
              .page-link {
                @extend %btn-cmn-nostyle;
                height: 20px;
                width: 20px;
                padding: 0;
                line-height: 0;
                border-radius: 2px;
                color: #1e1f22;
                background-color: #e6e6e6;
              }
            }
          }
        }
      }
    }
    .hrms-chat-resp-body-box {
      background-color: $chatbg;
      color: $chatcolor;
    }
    .hrms-chat-resp-body-foot {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-top: 20px;
      justify-content: space-between;
      button {
        @extend %btn-cmn-nostyle;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: inherit;
        color: #1e1f22;
        background-color: #b0d7cd;
        &.btn-emoji {
          margin-right: 5px;
          // display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 24px;
          max-width: 60px;
          // width: 100%;
          font-size: 16px;
          i {
            &.fa-face-smile {
              color: #ffbe55;
              background-color: black;
              border-radius: 50%;
            }
          }
        }
        &:hover,
        &:focus {
          background-color: #a0c7bd;
        }
      }
    }
  }
}

app-chathome {
  .hrms-chatwrap {
    position: relative;
    height: 100%;
    .hrms-chat-innerbox {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;
      .hrms-chat-sidebar-left {
        @extend %dft-transition;
        height: 100%;
        width: 100%;
        max-width: 350px;
        position: absolute;
        top: 0;
        left: -100%;
        &.is-expanded {
          left: 0;
        }
      }
      .hrms-chat-content-right {
        @extend %dft-transition;
        position: relative;
        margin-left: 0;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        background-color: #f5f5f5;
        &.is-expandedfull {
          max-width: calc(100% - 350px);
          margin-left: 350px;
        }
        .hrms-chat-inner-right-fluid {
          height: calc(100% - 15px);
        }
      }
    }
  }
  .hrms-chat-emptystate {
    display: flex;
    flex-direction: column;
    max-width: 920px;
    margin-inline: auto;
    @media screen and (min-width: 1919px) {
      margin-top: 100px;
    }

    .hrms-chat-emptystate-inner {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .hrms-chat-estate-top {
        max-width: 325px;
        margin-bottom: 140px;
        width: 100%;
        @include media-breakpoint-xxxl() {
          margin-top: 40px;
          margin-bottom: 80px;
        }
      }
      .hrms-chat-estate-btm {
        @include mixin-simplegrid(3, 1, 15px, 15px);
        .hrms-emp-state-card {
          .hrms-hiconbox {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 25px;
            color: $secondary;
            i {
              font-size: 26px;
              margin-bottom: 10px;
            }
            h2 {
              font-size: 18px;
              font-weight: 600;
            }
          }
          .hrms-empty-promptlist {
            display: flex;
            flex-direction: column;
            gap: 16px;
            > li {
              .hrms-prompt-btn {
                @extend %btn-cmn-nostyle;
                @include mixin-truncate(2);
                word-break: break-word;
                text-align: left;
                padding: 4px 8px;
                font-size: 14px;
                line-height: 20px;
                color: $secondary;
                border-radius: 8px;
                background-color: $light-grey2;
              }
            }
          }
        }
      }
    }
  }
  .hrms-mainchat-box {
    background-color: $light-grey1;
    // height: calc(100vh - 166px);
    height: calc(100vh - 100px);
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 30px;
    padding-bottom: 20px;
    .hrms-mainchat-inner {
      display: flex;
      flex-direction: column;
      max-width: 920px;
      margin-inline: auto;
      .hrms-chatconversation-list {
        display: flex;
        flex-direction: column;
        gap: 27px;
        margin-bottom: 0;
        > li {
          .hrms-chat-conv-item {
          }
        }
      }
    }
  }

  .hrms-chat-ip-msgbox {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 760px;
    width: 100%;
    padding: 5px 14px 5px 10px;
    border-radius: 26px;
    background-color: $white;
    margin: auto;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    button {
      padding: 1px;
      height: 40px;
      width: 40px;
    }
    textarea {
      background-color: transparent;
      border: none;
      resize: none;
      height: 36px;
      font-size: 14px;
      line-height: 20px;
      @include mixin-placeholder {
        color: rgba(0, 0, 0, 0.2);
      }
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
      &:disabled {
        cursor: no-drop;
        background-color: transparent;
        opacity: 0.7;
      }
    }
  }
}

app-cmn-profile-header {
  .header-right-box {
    position: fixed;
    right: 35px;
    top: 15px;
    z-index: 1;
    .hrms-auth-userprofile-btn {
      @include mixin-profile-circle(34px, 34px);
      padding: 0;
      &::after {
        display: none;
      }
    }
  }
}

.spinner-grow-sm {
  height: 10px;
  width: 10px;
}

.zoom-in-box {
  animation: zoom-in 300ms ease forwards;
}

@keyframes zoom-in {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}