//Media breakpoint

@mixin media-breakpoint-sm() {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin media-breakpoint-md() {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin media-breakpoint-lg() {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin media-breakpoint-lgx() {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin media-breakpoint-xl() {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin media-breakpoint-xxl {
  @media (max-width: 1399.98px) {
    @content;
  }
}

@mixin media-breakpoint-xxxl {
  @media (max-width: 1919.98px) {
    @content;
  }
}
