/* Common Classes Starts Here */
::selection {
  color: $white;
  background: $primary;
}

/* Common Styles for the ngx-toast starts here  */
.toast-container {
  .ngx-toastr {
    color: $white;
    &.toast-error {
      background: $danger;
    }
    &.toast-success {
      background: $success;
    }
    &.toast-warning {
      background: $warning;
    }
    &.toast-info {
      background: $info;
    }
  }
}
/* Common Styles for the ngx-toast ends here */

/* Common Buttons styles code starts here */

%btn-primary-theme1 {
  color: $white;
  &:disabled,
  &:hover,
  &:active,
  &:focus {
    color: $white !important;
  }
}

%btn-secondary-theme1 {
  color: $black;
  &:disabled,
  &:hover,
  &:active,
  &:focus {
    color: $black !important;
  }
}

%btn-danger-theme1 {
  color: $white;
  &:disabled,
  &:hover,
  &:active,
  &:focus {
    color: $white !important;
  }
}

%btn-tertiary-theme1 {
  color: $quaternary;
  &:disabled,
  &:hover,
  &:active,
  &:focus {
    color: $quaternary !important;
  }
}

%btn-quaternary-theme1 {
  color: $white;
  &:disabled,
  &:hover,
  &:active,
  &:focus {
    color: $white !important;
  }
}

%btn-outline-primary-theme1 {
  --bs-btn-active-color: #{$white};
  color: $primary;
  &:hover,
  &:active,
  &:focus {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}

a {
  color: $body-color;
  &:hover {
    color: $primary;
  }
}

button,
a {
  &.btn-primary {
    @extend %btn-primary-theme1;
  }

  &.btn-secondary {
    @extend %btn-secondary-theme1;
  }

  &.btn-danger {
    @extend %btn-danger-theme1;
  }

  &.btn-tertiary {
    @extend %btn-tertiary-theme1;
  }

  &.btn-quaternary {
    @extend %btn-quaternary-theme1;
  }

  &.btn-outline-primary {
    @extend %btn-outline-primary-theme1;
  }
}

/* Common Buttons styles code ends here */

/* Common Tooltip styles starts here */

.hrms-cmn-tooltip-type1 .tooltip-inner {
  background-color: #444f66;
  color: $white;
}
.hrms-cmn-tooltip-type1.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #444f66;
}
.hrms-cmn-tooltip-type1.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #444f66;
}
.hrms-cmn-tooltip-type1.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #444f66;
}
.hrms-cmn-tooltip-type1bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #444f66;
}
/* Common Tooltip styles ends here */

/* Common dropdown menu theme starts here */
.hrms-cmn-dropdown {
  > .dropdown-menu {
    background-color: #363940;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
    .dropdown-item {
      color: $white;

      &:hover,
      &:focus {
        color: $white;
        background-color: #25272b;
      }
    }
  }
}

/* Common dropdown menu theme ends here */

/* Common Form control code starts here */

%hrms-formcontrol-theme-typ1 {
  @include mixin-placeholder {
    color: #575b65;
  }
  border-color: $light-grey1;
  color: #575b65;
  background-color: $light-grey1;
  &:focus {
    color: #575b65;
    border-color: $primary;
    background-color: $light-grey1;
  }

  &:disabled {
    background-color: #eeeeee;
  }
}

%hrms-checkbox-ui-theme-typ1 {
  .form-check-input {
    border-color: #363940;
    background-color: $white;
    &:checked[type="checkbox"] {
      background-color: #363940;
    }
  }
  .form-check-label {
    color: $body-color;
  }
}

%hrms-radio-ui-theme-typ1 {
  .form-check-input {
    background-color: #363940;
    &:checked[type="radio"] {
      background-color: $primary;
    }
  }
  .form-check-label {
    color: $white;
  }
}

%hrms-select-ui-theme-typ1 {
  box-shadow: none;
  &:focus {
    border-color: $secondary;
  }
}

%hrms-ipcontrol-theme-typ1 {
  .hrms-cmn-ipg-btn {
    border-color: #ced4da;
    background-color: #f9fbfc;
    color: #111827;
  }
}

.hrms-formbox,
.hrms-formbox {
  label {
    color: $quaternary;
    &.form-label-with-icon {
      i {
        color: #919294;
      }
    }
  }
  .form-control {
    @extend %hrms-formcontrol-theme-typ1;
  }
  .hrms-chkbox-typ1 {
    @extend %hrms-checkbox-ui-theme-typ1;
  }
  .hrms-radio-typ1 {
    @extend %hrms-radio-ui-theme-typ1;
  }

  .form-select {
    @extend %hrms-select-ui-theme-typ1;
  }

  .input-group {
    @extend %hrms-ipcontrol-theme-typ1;
    .form-control {
      &:focus {
        + {
          .hrms-cmn-ipg-btn {
            color: $body-color;
            border-color: $secondary;
          }
        }
      }
    }
  }
}

/* Common Form control code ends here */

.hrms-cmn-loader-wrap {
  background-color: rgba(0, 0, 0, 0.8);
  color: $white;

  .hrms-cmn-loader-inner {
    .hrms-cmn-spinner {
      background-color: transparent;
      border-color: $secondary;
      border-top-color: $white;
    }
  }
}

.hrms-cmn-control-loader-wrap {
  background-color: transparent;
  border-color: $quaternary;
  border-top-color: $white;

  &.primary {
    border-color: $primary;
    border-top-color: $white;
  }
  &.secondary {
    border-color: $secondary;
    border-top-color: $white;
  }
  &.tertiary {
    border-color: $tertiary;
    border-top-color: $white;
  }
  &.quaternary {
    border-color: $quaternary;
    border-top-color: $white;
  }
}

app-not-found {
  .hrms-notfound-wrap {
    .notfound-inner {
      .not-found-content {
        p {
          color: #afb0b3;
        }
      }
    }
  }
}
