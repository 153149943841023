/*  Mixin Name -  mixin-placeholder
    Description:- To add the placeholder for all cross-browsers
    Usecase for mixin-placeholder
    Eg:-
    input,
    textarea {
        @include mixin-placeholder {
            color: #333333;
        }
    }

*/
@mixin mixin-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

/* =========================================================================================================== */
/*  Mixin Name -  mixin-truncate
    Description:- To truncate the content in just one line or more then one line
    Usecase for mixin-truncate
    Note:- Default line-clamp is 1
    Eg:- For one line
    .demopara
    {
        @include mixin-text-truncate()
    }

*/
@mixin mixin-text-truncate() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* =========================================================================================================== */
/*  Mixin Name -  mixin-truncate
    Description:- To truncate the content in just one line or more then one line
    Usecase for mixin-truncate
    Eg:- For 2 lines
    .demopara
    {
        @include mixin-truncate(2);
    }

*/
@mixin mixin-truncate($mixvar-numlines: 2) {
  @supports (-webkit-line-clamp: $mixvar-numlines) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: $mixvar-numlines;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}

/* =========================================================================================================== */
/*  Mixin Name -  mixin-borderstyle
    Description:- To add the grid layout for example displaying like gallery-list
    Usecase for mixin-borderstyle
    Variables Details:-
        $mixvar-border-dir - border directons possible values are:-  all | right | bottom | left
        $mixvar-border-width - any number with units eg:- 1px
        $mixvar-border-style - border styles possible values are:-  none | hidden | dotted | dashed | solid | double | groove | ridge | inset | outset | initial | inherit;
    Eg:-
    .demowrapper
    {
        @include mixin-borderstyle(all,1px,solid);
    }
*/

@mixin mixin-borderstyle($mixvar-border-dir, $mixvar-border-width, $mixvar-border-style)
{

  @if $mixvar-border-dir == all {
    border-width: $mixvar-border-width;
    border-style: $mixvar-border-style;
  } @else if $mixvar-border-dir == right {
    border-right-width: $mixvar-border-width;
    border-right-style: $mixvar-border-style;
  } @else if $mixvar-border-dir == bottom {
    border-bottom-width: $mixvar-border-width;
    border-bottom-style: $mixvar-border-style;
  } @else if $mixvar-border-dir == left {
    border-left-width: $mixvar-border-width;
    border-left-style: $mixvar-border-style;
  }@else if $mixvar-border-dir == top {
    border-top-width: $mixvar-border-width;
    border-top-style: $mixvar-border-style;
  } @else {
    @error "Unknown direction #{$mixvar-border-dir}";
    @error "Unknown width #{$mixvar-border-width}";
    @error "Unknown style #{$mixvar-border-style}";
  }

}
/* =========================================================================================================== */

/*  Mixin Name -  mixin-simplegrid
    Description:- To add the grid layout for example displaying like gallery-list
    Usecase for mixin-simplegrid
    Variables Details:-
        $mixvar-cols - No. of columns you need in your grid
        $mixvar-rows - No. of rows you need in your grid
        $mixvar-colgap - Sets the gap between the columns
        $mixvar-rowgap -  Sets the gap between the rows
    Eg:-
    demogallerylist
    {
        @include mixin-simplegrid(3,1,15px,15px);
    }

*/
@mixin mixin-simplegrid($mixvar-cols, $mixvar-rows, $mixvar-colgap, $mixvar-rowgap) {
  display: grid;
  grid-template-columns: repeat($mixvar-cols, 1fr);
  grid-template-rows: $mixvar-rows;
  grid-column-gap: $mixvar-colgap;
  grid-row-gap: $mixvar-rowgap;
}

/* =========================================================================================================== */
/*  Mixin Name -  mixin-simplegrid
    Description:- To add the grid layout for example displaying like gallery-list
    Usecase for mixin-simplegrid
    Variables Details:-
        $mixvar-width - sets the width of an element.  Default value is 20px
        $mixvar-height -sets the width of an element.  Default value is 20px
    Eg:-
    .profbox
    {
        @include mixin-profile-circle(20px,20px)
    }

*/

@mixin mixin-profile-circle($mixvar-width:20px, $mixvar-height:20px) {
  width: $mixvar-width;
  height: $mixvar-height;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
}