$zindex-hrms-loader: 1091;
$get-path-to-assetsimages: "../images/";
$hrms-sidebar-width: 280px;
$hrms-sidebar-maxactive-width: 280px;
$hrms-sidebar-minactive-width: 80px;

//Color Variables
$dark-grey1: #1e1e1e;
$light-grey1: #f5f5f5;
$light-grey2: #f7f9fb;
$light-grey3: #d6d6d6;
$titleheading: #002b3a;

