//Overide the Boostrap default Color
$body-bg: #ffffff;
$body-color: #6d717c;
$primary: #02ba6d;
$secondary: #002b3a;
$tertiary: #eaeaea;
$quaternary: #045b81;
$success: #038879;
$warning: #ba5802;
$info: #02a0ba;
$danger: #ba0202;
$chatbg: #e5efe5;
$chatcolor: #1e1f22;

/* ------------ Updated the default theme with new colors theme-map-merge starts here ------------  */
$theme-colors: () !default;

$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "warning": $warning,
        "info": $info,
        "danger": $danger,
        "tertiary": $tertiary,
        "quaternary": $quaternary,
        "chatbg": $chatbg,
        "chatcolor": $chatcolor //Need to define the custom color over here so it will populate in boostrap.css
    ),
    $theme-colors
);

/* ------------ Updated the default theme with new colors theme-map-merge ends here ------------  */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "ngx-toastr/toastr";
@import "prismjs/themes/prism-okaidia.css";
@import "prismjs/plugins/line-highlight/prism-line-highlight.css";
//@import "assets/fonts/fonts-styles";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

/* Importing Ng-select styles */
//@import "~@ng-select/ng-select/themes/default.theme.css";

$font-inter: "Inter", sans-serif;
$font-fontawesome: "Font Awesome 6 Free";

* {
    font-family: $font-inter;
    font-weight: 400;

    &:focus-within {
        border-color: unset;
    }

    &:focus-visible {
        border-color: unset;
    }
}

html,
body {
    height: 100vh;
    width: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: $font-inter;
    font-size: 16px;
    font-weight: 400;
    word-break: break-word;
    line-height: 24px;
    color: $body-color;
}

%dft-transition {
    -moz-transition: all 400ms ease-out;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
}

%dft-anchor-style {
    text-decoration: none;
    outline: none;
}

%dft-button-style {
    outline: none !important;
    box-shadow: none !important;
}

a {
    @extend %dft-transition;
    @extend %dft-anchor-style;

    &:link,
    &:visited,
    &:active,
    &:hover {
        @extend %dft-anchor-style;
    }
}

button {
    @extend %dft-button-style;

    &:disabled {
        cursor: not-allowed;
    }

    &:active,
    &:hover,
    &:focus,
    &:focus,
    &:focus-visible {
        @extend %dft-button-style;

        &:not(:focus-visible) {
            @extend %dft-button-style;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #111215 inset !important;
}

// input:-webkit-autofill::first-line {
//   font-family: 'Helvetica Neue', 'Arial', Arial, sans-serif !important;
//   font-size: 16px !important;
// }
/* Standard Css ends Here */
@import "assets/styles/breakpoint-mixins";
@import "assets/styles/custom-mixins";
@import "assets/styles/custom-variables";
@import "assets/styles/custom-theme";
@import "assets/styles/custom-style";

.markdown-clipboard-toolbar {
    top: 16px;
    right: 16px;
    opacity: 1;
    transition: opacity 250ms ease-out;
    color: $primary;
}

.markdown-clipboard-toolbar:hover {
    opacity: 1;
    color: $primary;
}
